import Vue from 'vue'
import { API_URLS, KEYS } from '@/js/const'
import VueLazyload from 'vue-lazyload'
import smoothscroll from 'smoothscroll-polyfill'
import '@/scss/style.scss'

smoothscroll.polyfill()
Vue.use(VueLazyload, {
  error: './img/common/dotted.png',
  preLoad: 1.3,
  attempt: 1
})

export default {
  data() {
    return {
      isScroll: false,
      isDrawer: false,
      scrollY: 0,
      isModal: false,
      modalType: 'search', // モーダル内の表示パターン
      searchObj: {}, // 検索項目情報
      commitmentObj: {}, // こだわり条件情報
      financialsObj: {}, // 金融機関情報
      commentsObj: {}, // クチコミ情報
      modalSearch: {}, // modalで表示する検索内容
      userSelects: {}, // ユーザーの選択結果(select)
      userChecks: { commitments: [], nofinancials: [] }, // ユーザーの選択結果(checkbox)
      checkedCommitments: [], // checked用
      checkedNofinancials: [], // checked用
      comments: {}, // 各金融機関のクチコミ1件
      commentFinancialName: '', // クチコミモーダル用金融機関名
      financialComments: {}, // クチコミモーダル用クチコミ一覧情報
      pcScreen: true,
      spWidth: 750
    }
  },
  computed: {
    pickupFinancial() {
      let pickupFinancial = {}
      if (this.financialsObj && Object.keys(this.financialsObj).length) {
        pickupFinancial = this.financialsObj.find(elm => elm.pickupFlag === true)
      }
      return pickupFinancial
    }
  },
  watch: {
    commentsObj(commentsObj) {
      if (commentsObj && Object.keys(commentsObj).length) {
        // それぞれの金融機関用口コミ1件のObject作成
        commentsObj.forEach(elm => {
          if (!this.comments[elm.master.fId]) {
            this.comments[elm.master.fId] = elm
          }
        })
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    // 初期の横幅取得
    this.pcScreen = window.innerWidth <= this.spWidth ? false : true
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    window: (onload = function () {
      const loader = document.getElementById('loading-wrap')
      loader.classList.add('loading-completed')
    }),
    handleScroll() {
      this.scrollY = window.scrollY
      this.isScroll = this.scrollY ? true : false
      // エフェクト/スクロールイン
      const fadetag = document.getElementsByClassName('fadein')
      Array.from(fadetag).forEach(elm => {
        if (this.scrollY > elm.offsetTop - window.innerHeight + 50) {
          elm.classList.add('scrollin')
        }
      })
    },
    handleResize() {
      this.pcScreen = window.innerWidth <= this.spWidth ? false : true
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    times() {
      const dt = new Date()
      const y = dt.getFullYear()
      const m = `00${dt.getMonth() + 1}`.slice(-2)
      const d = `00${dt.getDate()}`.slice(-2)
      const h = `00${dt.getHours()}`.slice(-2)
      const i = `00${dt.getMinutes()}`.slice(-2).slice(0, 1)
      return `${y}${m}${d}${h}${i}`
    },
    apiUrl(code) {
      if (process.env.NODE_ENV === 'production') {
        return `${API_URLS[process.env.NODE_ENV]}${code}.php?t=${this.times()}`
      } else if (process.env.NODE_ENV === 'development') {
        return `${API_URLS[process.env.NODE_ENV]}${code}.json?t=${this.times()}`
      }
    },
    openSearchModal(sId) {
      if (sId) {
        this.isModal = true
        const modalQuestion = this.searchObj.filter((elm, index) => {
          let obj = {}
          if (elm.sId === sId) {
            obj = elm
            obj['index'] = index
            return obj
          }
        })
        this.modalSearch = modalQuestion[0]
        this.modalType = 'search'
      }
    },
    userSelect(newindex, sId, key) {
      // 解答用Object生成
      this.userSelects[sId] = key
      // 次の設問取得
      const nextModailQuestion = this.searchObj.filter((elm, index) => {
        let obj = {}
        if (Number(index) === newindex + 1) {
          obj = elm
          obj['index'] = index
          return obj
        }
      })
      if (nextModailQuestion[0] && Object.keys(nextModailQuestion[0]).length) {
        this.modalSearch = nextModailQuestion[0]
        this.isModal = false
        setTimeout(() => {
          this.isModal = true
        }, 300)
      } else {
        this.isModal = false
      }
    },
    userCheck(key, cId) {
      if (this.userChecks[key].indexOf(cId) !== -1) {
        this.userChecks[key] = this.userChecks[key].filter((elm) => {
          return elm !== cId
        })
      } else {
        this.userChecks[key].push(cId)
      }
    },
    modalCheck() {
      this.isModal = true
      this.modalType = 'nofinancials'
    },
    search() {
      const search = {}
      search.selects = this.userSelects
      search.checks = this.userChecks
      localStorage.setItem(KEYS.SEARCH, JSON.stringify(search))
      location.href = './result.html'
    },
    starRating(num) {
      const decimal = Number(this.decimalPart(num, 2))
      if (decimal * 10 < 2.5) {
        return Math.floor(num)
      } else if (decimal * 10 >= 2.5 && decimal * 10 < 7.5) {
        return `${Math.floor(num)}.5`
      } else {
        return Math.floor(num) + 1
      }
    },
    decimalPart(num) {
      const numStr = num + ''
      const dotIdx = numStr.indexOf('.')
      const result = '0.' + (dotIdx > -1 ? numStr.substring(dotIdx + 1) : '0')
      return parseFloat((num > 0 ? '+' : '-') + result)
    },
    gotoFinancial(fCode) {
      window.open(`./redirect/${fCode}.html`)
    },
    openCommentModal(fId) {
      this.isModal = true
      this.modalType = 'comment'
      this.financialComments = this.commentsObj.filter(elm => {
        return elm.master.fId === fId
      })
      const financial = this.financialsObj.find(elm => elm.master.fId === fId)
      this.commentFinancialName = financial.financialName
    },
    gotoDetail(fCode) {
      // 詳細ページに遷移
      location.href = `./${fCode}.html`
    }
  },
  filters: {
    truncate(str, limit) {
      if (str.length > limit) {
        str = str.substring(0, (limit - 3)) + '...'
      }
      return str
    },
    emptyMark(str) {
      if (!str) {
        str = '―'
      }
      return str
    }
  }
}