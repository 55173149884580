import Vue from 'vue'
import axios from 'axios'
import Mixin from './mixins/mixin'
import { KEYS } from './const'

new Vue({
  el: '#app',
  mixins: [Mixin],
  data() {
    return {
      rankingObj: [], // ランキング情報
      showRankingCount: 3 // 表示するランキング件数
    }
  },
  watch: {
    financialsObj(financialsObj) {
      // 初期表示用タブ内容設定
      if (financialsObj && Object.keys(financialsObj).length) {
        this.rankingObj = this.financialsObj.slice(0, this.showRankingCount)
      }
    }
  },
  created() {
    // 検索項目情報JSON取得
    const self = this
    axios.get(this.apiUrl('search'))
      .then(res => {
        self.searchObj = res.data.contents
      }).catch(error => {
        console.log(error)
      })
    // こだわり条件情報JSON取得
    axios.get(this.apiUrl('commitment'))
      .then(res => {
        self.commitmentObj = res.data.contents
      }).catch(error => {
        console.log(error)
      })
    // 金融機関情報JSON取得
    axios.get(this.apiUrl('financials'))
      .then(res => {
        self.financialsObj = res.data.contents
      }).catch(error => {
        console.log(error)
      })
    // 口コミ情報JSON取得
    axios.get(this.apiUrl('comments'))
      .then(res => {
        self.commentsObj = res.data.contents
      }).catch(error => {
        console.log(error)
      })
    // localStorageクリア
    localStorage.removeItem(KEYS.SEARCH)
  }
})
