/**
 * API用参照URL
 */
export const API_URLS = {
  development: './json/',
  production: './api/'
}

/**
 * localStorage
 */
export const KEYS = {
  SEARCH: 'Ju6emRKB'
}
